import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function HeadingLarge({
  as,
  fontWeight,
  uppercase,
  italic,
  color,
  textAlign,
  children,
}) {
  return (
    <Text
      as={as}
      fontWeight={fontWeight}
      uppercase={uppercase}
      italic={italic}
      fontSize={[32, 32, 62, 62]}
      color={color}
      lineHeight={1}
      textAlign={textAlign}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}

export default HeadingLarge;

HeadingLarge.defaultProps = {
  as: "h1",
  color: "inherit",
};

HeadingLarge.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  uppercase: PropTypes.bool,
  textAlign: PropTypes.string,
};
