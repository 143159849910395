import Box from "./Box";
import React from "react";

function PageContent({ children, bg }) {
  return (
    <Box bg={bg} flex={1}>
      <Box mt={-60}>{children}</Box>
    </Box>
  );
}

export default PageContent;
