import React, { useLayoutEffect, useRef, useState, useEffect } from "react";

import Box from "./Box";
import PropTypes from "prop-types";
import Text from "./Text";

function Tooltip({ text, icon, active }) {
  const ref = useRef();
  const [bottom, setBottom] = useState(0);

  // Prevent useLayoutEffect warning
  // https://github.com/react-component/overflow/issues/6
  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    setBottom(ref.current.offsetHeight);
  }, []);

  return (
    <Box
      ref={ref}
      bg="lightYellow"
      width={204}
      boxShadow="0 0 30px rgba(0,0,0,0.2)"
      p={15}
      position="absolute"
      right={`calc(50% - 102px)`}
      bottom={ref.current ? `-${bottom + 20}px` : 0}
      transform={active ? "translate3d(0,0,0)" : "translate3d(0,-10px,0)"}
      opacity={active ? 1 : 0}
      pointerEvents="none"
      transition="opacity 200ms, transform 200ms ease-out"
      zIndex
    >
      {icon}
      <Text fontSize={[12, 12, 12, 12]} lineHeight="17px" mt="3px">
        {text}
      </Text>
      <img
        src="/images/pointy-arrow.svg"
        alt=""
        role="presentation"
        style={{
          position: "absolute",
          top: -16,
          left: "calc(50% - 8px)",
          transform: "rotate(180deg)",
        }}
      />
    </Box>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};
