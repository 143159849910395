import React, { useRef } from "react";

import Box from "./Box";
import PropTypes from "prop-types";
import Text from "./Text";
import { useHovered } from "../core/hooks";

function LinkButtonSecondaryInner({ bg, color, text, active }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  return (
    <Box bg={bg} mr="1px" p="1px">
      <Box
        ref={ref}
        p="10px"
        transition="background-color 200ms"
        bg={hovered || active ? "white" : bg}
      >
        <Text
          color={bg === "black" && (hovered || active) ? "black" : color}
          uppercase
          fontSize={[12, 12, 12, 12]}
          transition="color 200ms"
          fontWeight={500}
          fontFamily="ibmPlexMono"
          lineHeight={1}
          letterSpacing="2px"
          whiteSpace="nowrap"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
}

export default LinkButtonSecondaryInner;

LinkButtonSecondaryInner.propTypes = {
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
};
