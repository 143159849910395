import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function HeadingSmall({
  as,
  fontWeight,
  uppercase,
  underline,
  italic,
  color,
  children,
}) {
  return (
    <Text
      as={as}
      fontWeight={fontWeight}
      uppercase={uppercase}
      underline={underline}
      italic={italic}
      fontSize={[22, 22, 32, 32]}
      lineHeight={["32px", "32px", "42px", "42px"]}
      color={color}
      dangerouslySetInnerHTML={{ __html: children }}
    ></Text>
  );
}

export default HeadingSmall;

HeadingSmall.defaultProps = {
  as: "h2",
  color: "inherit",
};

HeadingSmall.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  uppercase: PropTypes.bool,
};
