import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function HeadingMedium({ as, fontWeight, uppercase, italic, color, children }) {
  return (
    <Text
      as={as}
      fontWeight={fontWeight}
      uppercase={uppercase}
      italic={italic}
      fontSize={[32, 32, 42, 42]}
      lineHeight={1}
      color={color}
    >
      {children}
    </Text>
  );
}

export default HeadingMedium;

HeadingMedium.defaultProps = {
  as: "h2",
  color: "inherit",
};

HeadingMedium.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  uppercase: PropTypes.bool,
};
