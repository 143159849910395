import React from "react";
import Text from "./Text";

function BodyText(props) {
  return (
    <Text lineHeight="26px" fontSize={[16, 16, 16, 16]} {...props}>
      {props.children}
    </Text>
  );
}

export default BodyText;
