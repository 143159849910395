import Box from "./Box";
import LinkButtonSecondary from "./LinkButtonSecondary";
import PropTypes from "prop-types";
import React from "react";

function ProductCategoryButtons({ product, brandSlug }) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      display={["none", "flex", null, null]}
      alignItems="flex-end"
      flexWrap="wrap"
      zIndex={2}
      // Prevents visual glitch on mobile during animation
      transform="translate3d(0,0,0)"
    >
      {product.rootCategory && (
        <LinkButtonSecondary
          color="black"
          bg={product.rootCategory.slug.replace("/", "")}
          text={product.rootCategory.name}
          href={
            brandSlug
              ? `${brandSlug}${product.rootCategory.slug}`
              : product.rootCategory.slug
          }
        />
      )}

      {product.category && (
        <LinkButtonSecondary
          color="black"
          bg="lightYellow"
          text={product.category.name}
          href={
            brandSlug
              ? `${brandSlug}${product.rootCategory.slug}/${product.category.slug}`
              : `${product.rootCategory.slug}/${product.category.slug}`
          }
        />
      )}
    </Box>
  );
}

export default ProductCategoryButtons;

ProductCategoryButtons.propTypes = {
  product: PropTypes.shape({
    rootCategory: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  brandSlug: PropTypes.string,
};
