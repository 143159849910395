import Box from "./Box";
import { CONDITION_MAP } from "../core/constants";
import PropTypes from "prop-types";
import React from "react";
import SmallCaps from "./SmallCaps";
import Tooltip from "./Tooltip";

function ItemCondition({ condition, showTooltip, tooltipActive }) {
  const Icon = CONDITION_MAP[condition].icon;
  return (
    <Box display="flex" alignItems="center">
      {showTooltip && (
        <Tooltip
          text={CONDITION_MAP[condition].tooltipText}
          icon={<Icon aria-label={CONDITION_MAP[condition].label} />}
          active={tooltipActive}
        />
      )}
      <Icon aria-label={CONDITION_MAP[condition].label} />
      <Box pl="9px">
        <SmallCaps>{CONDITION_MAP[condition].label}</SmallCaps>
      </Box>
    </Box>
  );
}

export default ItemCondition;

ItemCondition.propTypes = {
  condition: PropTypes.string.isRequired,
};
