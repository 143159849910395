import BodyText from "./BodyText";
import Box from "./Box";
import PropTypes from "prop-types";
import React from "react";
import { formatPrice } from "../core/utils";

function ItemPrice({ price, small }) {
  return (
    <Box display="flex">
      <BodyText
        fontWeight={700}
        lineHeight={[1, null, null, null]}
        fontSize={small ? [12, 14, 14, 14] : undefined}
        as="span"
      >
        {formatPrice(price)}
      </BodyText>
    </Box>
  );
}

export default ItemPrice;

ItemPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  small: PropTypes.bool,
};
