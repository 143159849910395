import { FILL, LEFT, LIGHT } from "../core/constants";

import Box from "./Box";
import HeadingMedium from "./HeadingMedium";
import Image from "./Image";
import LinkButtonPrimary from "./LinkButtonPrimary";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function PromoBlock({
  heading,
  text,
  image,
  imagePos,
  ctaLink,
  ctaText,
  externalLink,
}) {
  return (
    <Box
      bg="black"
      display="flex"
      flexDirection={[
        imagePos === LEFT ? "column-reverse" : "column",
        null,
        imagePos === LEFT ? "row-reverse" : "row",
      ]}
      height={[null, null, 760, null]}
    >
      <Box
        width={["100%", null, "50%", null]}
        px={[20, null, 70, null]}
        py={[50, null, 70, null]}
      >
        <Box
          maxWidth={600}
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <HeadingMedium color="white" uppercase>
            {heading}
          </HeadingMedium>
          <Text
            pt={36}
            color="white"
            fontSize={[16, 16, 22, 22]}
            lineHeight={["26px", "26px", "32px", "32px"]}
          >
            {text}
          </Text>
          {ctaLink && ctaText && (
            <Box mt={[25, null, 35, null]}>
              <LinkButtonPrimary
                href={ctaLink}
                external={externalLink}
                theme={LIGHT}
              >
                {ctaText}
              </LinkButtonPrimary>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        width={["100%", null, "50%", null]}
        position="relative"
        height={[0, null, "initial", null]}
        pb={["100%", null, 0, null]}
        bg="lightGrey"
      >
        {image && (
          <Image
            src={image.src}
            alt={image.alt}
            objectFit="cover"
            sizes="(max-width: 960px) 100vw, 50vw"
          />
        )}
      </Box>
    </Box>
  );
}

export default PromoBlock;

PromoBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  imagePos: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
};
