import Box from "./Box";
import ProductLink from "./ProductLink";
import PropTypes from "prop-types";
import React from "react";
import ReskinnedSwiper from "./ReskinnedSwiper";
import { LATEST_DROP_URL } from "../core/urls";

function LatestDrop({ title, latestDrop }) {
  return (
    <ReskinnedSwiper
      href={
        latestDrop.brandSlug
          ? `${latestDrop.brandSlug}${LATEST_DROP_URL}`
          : LATEST_DROP_URL
      }
      heading={title}
      slidesPerView={3.3}
      items={latestDrop.products.map((product) => {
        return (
          <ProductLink
            key={product.slug}
            product={product}
            brandSlug={latestDrop.brandSlug ? latestDrop.brandSlug : null}
            showCondition
            showCategoryButtons
          />
        );
      })}
    />
  );
}

export default LatestDrop;

LatestDrop.propTypes = {
  title: PropTypes.string.isRequired,
  latestDrop: PropTypes.shape({
    products: PropTypes.array.isRequired,
  }).isRequired,
};
