import { DARK, LIGHT } from "../core/constants";

import Box from "./Box";
import Button from "./Button";
import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";
import SmallCaps from "./SmallCaps";

function LinkButtonPrimary({ theme, href, fullWidth, external, children }) {
  const inner = (
    <Box
      bg={theme === DARK ? "black" : "white"}
      px={10}
      height={30}
      width={fullWidth ? "100%" : ""}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      border={theme === DARK ? "1px solid black" : "1px solid white"}
      color={theme === DARK ? "white" : "black"}
      transition="background-color 200ms, color 200ms"
      hover={
        theme === DARK
          ? "background-color: white; color: black"
          : "background-color: black; color: white"
      }
    >
      <SmallCaps color="inherit">{children}</SmallCaps>
    </Box>
  );

  if (external) {
    return (
      <a href={href} target="_blank">
        <Box>{inner}</Box>
      </a>
    );
  }
  return (
    <Link href={href}>
      <Button as="a">{inner}</Button>
    </Link>
  );
}

export default LinkButtonPrimary;

LinkButtonPrimary.propTypes = {
  href: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  external: PropTypes.bool,
};
