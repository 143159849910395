import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import Box from "./Box";
import ChevronIconBlack from "../assets/chevron.svg";
import ChevronIconWhite from "../assets/chevron-white.svg";
import HeadingSmall from "./HeadingSmall";
import LargeLink from "./LargeLink";
import PropTypes from "prop-types";

SwiperCore.use([Navigation]);

function ReskinnedSwiper({
  heading,
  href,
  items,
  slidesPerView,
  extraVerticalSpace,
}) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const showNav = items.length > slidesPerView;

  return (
    <>
      <div className="reskinned-swiper">
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          slidesPerView={1.4}
          spaceBetween={20}
          threshold={10}
          breakpoints={{
            768: {
              slidesPerView: 2.25,
              spaceBetween: 20,
            },
            960: {
              slidesPerView: slidesPerView,
              spaceBetween: "2.38%",
            },
            1920: {
              slidesPerView: slidesPerView * 1.2,
            },
          }}
        >
          <Box mb={[23, null, 40, null]}>
            <Box position="relative" width="100%">
              <Box mb={extraVerticalSpace ? 20 : 0}>
                {typeof href !== "undefined" ? (
                  <LargeLink text={heading} href={href} />
                ) : (
                  <HeadingSmall uppercase>{heading}</HeadingSmall>
                )}
              </Box>

              {showNav && (
                <div className="reskinned-swiper-nav">
                  <div className="swiper-button-prev" ref={navigationPrevRef}>
                    <div className="button-inner">
                      <div className="button-inner-white">
                        <ChevronIconBlack />
                      </div>
                      <div className="button-inner-black">
                        <ChevronIconWhite />
                      </div>
                    </div>
                  </div>
                  <div className="swiper-button-next" ref={navigationNextRef}>
                    <div className="button-inner">
                      <div className="button-inner-white">
                        <ChevronIconBlack />
                      </div>
                      <div className="button-inner-black">
                        <ChevronIconWhite />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
          {items.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Box width="100%">{item}</Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default ReskinnedSwiper;

ReskinnedSwiper.propTypes = {
  heading: PropTypes.string.isRequired,
  href: PropTypes.string,
  items: PropTypes.array.isRequired,
  slidesPerView: PropTypes.number.isRequired,
  extraVerticalSpace: PropTypes.bool,
};
