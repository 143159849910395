import React, { useRef } from "react";

import Box from "./Box";
import Button from "./Button";
import ItemCondition from "./ItemCondition";
import ItemPrice from "./ItemPrice";
import Link from "next/link";
import ProductCategoryButtons from "./ProductCategoryButtons";
import PropTypes from "prop-types";
import SquareImage from "./SquareImage";
import Text from "./Text";
import { useHovered } from "../core/hooks";
import { UNISEX } from "../core/constants";

function ProductLink({
  product,
  showBrand,
  brandSlug,
  showCondition,
  showCategoryButtons,
}) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  const slug = brandSlug
    ? `${brandSlug}/product${product.slug}`
    : `/product${product.slug}`;

  return (
    <Box>
      <Box ref={ref} position="relative" height={0} pb="100%" overflow="hidden">
        <Link href={slug} passHref>
          <Button as="a" position="absolute" height="100%" width="100%">
            {product.featuredImage && (
              <Box
                transition="transform 300ms ease-out"
                transform={
                  hovered ? "scale3d(1.05,1.05,1.05)" : "scale3d(1,1,1)"
                }
              >
                <SquareImage
                  src={product.featuredImage.url}
                  alt={product.title}
                  sizes="(max-width: 960px) 50vw, 25vw"
                />
              </Box>
            )}

            {showCondition && product.condition && (
              <Box
                display={["none", null, "block", null]}
                position="absolute"
                bottom={16}
                right={19}
              >
                <ItemCondition condition={product.condition} />
              </Box>
            )}
          </Button>
        </Link>
        {showCategoryButtons && product.gender !== UNISEX && (
          <ProductCategoryButtons product={product} brandSlug={brandSlug} />
        )}
      </Box>
      <Link href={slug} passHref>
        <Button as="a">
          <Box
            pt={12}
            display={["block", null, null, "flex"]}
            justifyContent="space-between"
          >
            <Box>
              <Text
                fontSize={[12, 14, 14, 14]}
                lineHeight={["14px", "16px", "16px", "16px"]}
                color="black"
                uppercase
                fontWeight={500}
                fontFamily="ibmPlexMono"
              >
                {product.title}
                {product.size ? ` (${product.size})` : ""}
              </Text>
              {showBrand && (
                <Text
                  fontSize={[10, 12, 12, 12]}
                  lineHeight={["12px", "14px", "14px", "14px"]}
                  color="black"
                  uppercase
                  fontWeight={700}
                  fontFamily="ibmPlexMono"
                  mt={["2px", null, "4px", null]}
                >
                  {product.brand.name}
                </Text>
              )}
            </Box>
            <Box mt={["2px", null, "0", null]}>
              <ItemPrice small price={product.priceData.price} />
            </Box>
          </Box>
        </Button>
      </Link>
    </Box>
  );
}

export default ProductLink;

ProductLink.propTypes = {
  product: PropTypes.shape({
    priceData: PropTypes.shape({
      price: PropTypes.string.isRequired,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  showCondition: PropTypes.bool,
  showBrand: PropTypes.bool,
};
