import Box from "./Box";
import Button from "./Button";
import Link from "next/link";
import LinkButtonSecondaryInner from "./LinkButtonSecondaryInner";
import PropTypes from "prop-types";
import React from "react";

function LinkButtonSecondary({ bg, color, text, href, external }) {
  if (external) {
    return (
      <a href={href} target="_blank">
        <Box>
          <LinkButtonSecondaryInner bg={bg} color={color} text={text} />
        </Box>
      </a>
    );
  }
  return (
    <Link href={href} passHref>
      <Button as="a">
        <LinkButtonSecondaryInner bg={bg} color={color} text={text} />
      </Button>
    </Link>
  );
}

export default LinkButtonSecondary;

LinkButtonSecondary.propTypes = {
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool,
};
